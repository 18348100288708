import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown/with-html";
import slugify from '../../utils/slugify';
import Link from '../link';

import './homepage-hero.scss';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import BackgroundImage from 'gatsby-background-image';

const HomepageHero = () => {
    const data = useStaticQuery(graphql`
        query {
            strapiHomepage {
                heroContent
                heroTitle
                heroBackgroundImage {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            allStrapiServices {
                nodes {
                    serviceDisplayName
                    serviceName
                    displayOnHomepage
                    id
                }
            }
        }
    `)
    return (
        <BackgroundImage Tag="section" id="homepage-hero" className="homepage-hero" fluid={data.strapiHomepage.heroBackgroundImage.childImageSharp.fluid}>
            <div className="hero-text-container">
                <div className="hero-text">
                    <div className="hero-title h1">
                        <ReactMarkdown source={data.strapiHomepage.heroTitle} />
                    </div>
                    <ReactMarkdown source={data.strapiHomepage.heroContent} />
                </div>
            </div>
            <div className="homepage-services">
                {
                    Array.from(data.allStrapiServices.nodes).reverse().map(service => (
                        service.displayOnHomepage ? 
                            <div key={service.id} className="service">
                                <Link to={`/oferta/${slugify(service.serviceName)}`}>
                                    <div className="title">
                                        <ReactMarkdown escapeHtml={false} source={service.serviceDisplayName} />
                                    </div>
                                </Link>
                            </div>
                        : null
                    ))
                }
            </div>
            <div className="link-to-services">
                <Link className="articles-archive-btn" to="/oferta">Przejdź do oferty <Icon icon={faCaretRight}/></Link>
            </div>
        </BackgroundImage>
    )
}

export default HomepageHero;