import React from "react"
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown/with-html";

import Layout from '../components/layout';
import HomepageHero from "../components/homepage-hero/homepage-hero";
import ImageAndText from '../components/image-and-text/image-and-text';
import LatestArticles from "../components/latest-articles/latest-articles";
import Contact from '../components/contact/contact';

export default function HomePage({ data }) {
  return (
    <Layout transparentHeader>
      <HomepageHero />
      <ImageAndText img={data.strapiHomepage.aboutUsImage.childImageSharp.fluid} bg={data.aboutBg.childImageSharp.fluid} id="about-us">
        <h1 className="light border"><ReactMarkdown source={data.strapiHomepage.aboutUsTitle} /></h1>
        <ReactMarkdown source={data.strapiHomepage.aboutUsContent} />
        <a className="line-button" style={{fontWeight: '400', float: 'right'}}>Dowiedz się więcej</a>
      </ImageAndText>
      <LatestArticles />
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    strapiHomepage {
      aboutUsContent
      aboutUsTitle
      aboutUsImage {
        childImageSharp {
            fluid(maxWidth: 557) {
                ...GatsbyImageSharpFluid
            }
        }
      }
    }
    aboutBg: file(relativePath: {eq: "about_bg.jpg"}) {
      id
      childImageSharp {
          fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
          }
      }
    }
  }
`
