import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Slider from 'react-slick';
import moment from 'moment/min/moment-with-locales';
import slugify from "../../utils/slugify";

import Container from '../container/container';
import Link from "../link";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './latest-articles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = ({className, onClick}) => {
    return (
        <div className={className} onClick={onClick}/>
    );
}
const PreviousArrow = ({className, onClick}) => {
    return (
        <div className={className} onClick={onClick}/>
    );
}

const settings = {
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: false,
    nextArrow: <NextArrow />,
    previous: <PreviousArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
}

const LatestArticles = () => {
    moment.locale('pl');
    const data = useStaticQuery(graphql`
        {
            latestArticles: allStrapiBlog(limit: 5) {
                nodes {
                    postTitle
                    published_at
                    postThumbnail {
                        childImageSharp {
                            fluid(maxWidth: 768) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    categories {
                        categoryName
                    }
                    strapiId
                }
            }
            articlesBg: file(relativePath: {eq: "articles_bg.jpg"}) {
                id
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <BackgroundImage Tag="section" id="homepage-latest-articles" className="homepage-latest-articles" fluid={data.articlesBg.childImageSharp.fluid}>
            <Container>
                <h1 className="border">Aktualności</h1>
                <Slider {...settings}>
                    {
                        data.latestArticles.nodes.map(article => {
                            const articleDate = `${moment(article.published_at).format('LL')}`;
                            return (
                                <div className="article">
                                    <Link to={`/blog/${slugify(article.postTitle)}`}>
                                        <BackgroundImage fluid={article.postThumbnail.childImageSharp.fluid} className="thumb">
                                            <div className="white-mask"></div>
                                        </BackgroundImage>
                                        <div className="article-data">
                                            <div className="title"><h4>{article.postTitle}</h4></div>
                                            <div className="article-meta">
                                                {`${article.categories.categoryName} | ${articleDate}`}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </Slider>
                <Link className="articles-archive-btn" to="/blog/">Archiwum aktualności <Icon icon={faCaretRight}/></Link>
            </Container>
        </BackgroundImage>
    )
}

export default LatestArticles;